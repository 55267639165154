import './App.css';
// import RandomBible from './RandomBible';
import data from './data.json'
import Description from './Description';
import Footer from './Footer';
import { useEffect, useRef, useState } from 'react';

function App() {

  // const KJVid = "de4e12af7f28f599-02";
  // const bookIDs = ['GEN', 'EXO', 'LEV', 'NUM', 'DEU', 'JOS', 'JDG', 'RUT', '1SA', '2SA', '1KI', '2KI', '1CH', '2CH', 'EZR', 'NEH', 'EST', 'JOB', 'PSA', 'PRO', 'ECC', 'SNG', 'ISA', 'JER', 'LAM', 'EZK', 'DAN', 'HOS', 'JOL', 'AMO', 'OBA', 'JON', 'MIC', 'NAM', 'HAB', 'ZEP', 'HAG', 'ZEC', 'MAL', 'MAT', 'MRK', 'LUK', 'JHN', 'ACT', 'ROM', '1CO', '2CO', 'GAL', 'EPH', 'PHP', 'COL', '1TH', '2TH', '1TI', '2TI', 'TIT', 'PHM', 'HEB', 'JAS', '1PE', '2PE', '1JN', '2JN', '3JN', 'JUD', 'REV'];
  // const chapIDs = [];
  const verseIDs = data;

  const randomVerseID = verseIDs[Math.floor(Math.random()*verseIDs.length)]
  let randomVerseJson = useRef()
  const [content, setContent] = useState("Just a moment...")
  const [title, setTitle] = useState("Title")


  
  useEffect(() => {
    fetch(`https://api.scripture.api.bible/v1/bibles/de4e12af7f28f599-02/verses/${randomVerseID}?content-type=text&include-notes=false&include-titles=false&include-chapter-numbers=false&include-verse-numbers=false&include-verse-spans=false&use-org-id=false`,{
      method: "GET",
      headers: {
        "api-key": "2842d26e9586d60c9480cfba6db219f1"
      }
    })
    .then(res => res.json())
    .then(da => {
      randomVerseJson.current = da["data"]
      
      setContent(randomVerseJson.current["content"])
      setTitle(randomVerseJson.current["reference"])
    })
    .catch(err => console.log(err))
  }, [])

  function nextV() {
    fetch(`https://api.scripture.api.bible/v1/bibles/de4e12af7f28f599-02/verses/${randomVerseJson.current["next"]["id"]}?content-type=text&include-notes=false&include-titles=false&include-chapter-numbers=false&include-verse-numbers=false&include-verse-spans=false&use-org-id=false`,{
      method: "GET",
      headers: {
        "api-key": "2842d26e9586d60c9480cfba6db219f1"
      }
    })
    .then(res => res.json())
    .then(da => {
      randomVerseJson.current = da["data"]
      
      setContent(randomVerseJson.current["content"])
      setTitle(randomVerseJson.current["reference"])
    })
    .catch(err => console.log(err))
  }

  
  function prevV() {
    fetch(`https://api.scripture.api.bible/v1/bibles/de4e12af7f28f599-02/verses/${randomVerseJson.current["previous"]["id"]}?content-type=text&include-notes=false&include-titles=false&include-chapter-numbers=false&include-verse-numbers=false&include-verse-spans=false&use-org-id=false`,{
      method: "GET",
      headers: {
        "api-key": "2842d26e9586d60c9480cfba6db219f1"
      }
    })
    .then(res => res.json())
    .then(da => {
      randomVerseJson.current = da["data"]
      
      setContent(randomVerseJson.current["content"])
      setTitle(randomVerseJson.current["reference"])
    })
    .catch(err => console.log(err))
  }

  return (
    <>
      <div className="main">
        <h1>Random Bible Verse</h1>
        <div className="rand-bible">
          <h2>{title}</h2>
          <p>{content}</p>
          <form action="">
            <input type="button" value="Previous verse" onClick={prevV}/>
            <input type="button" value="Next verse" onClick={nextV}/>
            <br />
            <input type="submit" value="Random verse"/>
          </form>
        </div>
        <Description />
      </div>
        <Footer />
    </>
  );
}

export default App;
