import React from 'react'

export default function Description() {
  return (
    <div className='description'>
      <h3>Description</h3>
      <p>
        The tool gives you a random verse from the Bible. If you want to read further from the verse you can get the next verse by pressing the button "Next verse"
      </p>
      <p>
        The Bible is a book that gets you thinking, which is good regardless of you religion or background. That is one of the reasons why I chose the Bible.
      </p>
      <p>
        The idea for the site came from from Esa Saarinen's podcast. He mentioned a reading technique and this is a digital implementation of the technique for the Bible. 
      </p>
      <p>
        The texts from the Bible are provided using <a href="https://scripture.api.bible/" target="_blank" rel="noopener noreferrer">API.Bible</a>
      </p>
    </div>
  )
}
